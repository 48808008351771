import React, { useState, useEffect } from "react"
import { Container, Col, Row, Image, Button } from "react-bootstrap"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CardsTypeRando from "../components/cards-type-rando"
import CardProchainsDeparts from "../components/card-prochains-departs"
import TablePrestations from "../components/table-prestations"
import ModalReservation from "../components/modal-reservation"
import guide from "../images/guide.jpg"
import goat from "../images/goat.png"
import { getPartialNextDepartures, getAllNextDepartures } from "../services/utils"

const responsive = {
  xl: {
    breakpoint: { max: 4000, min: 1200 },
    items: 3,
    partialVisibilityGutter: 30
  },
  lg: {
    breakpoint: { max: 1200, min: 992 },
    items: 2,
    partialVisibilityGutter: 30
  },
  md: {
    breakpoint: { max: 992, min: 768 },
    items: 2,
    partialVisibilityGutter: 30
  },
  sm: {
    breakpoint: { max: 768, min: 576 },
    items: 1,
    partialVisibilityGutter: 30
  },
  xs: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
    partialVisibilityGutter: 30
  }
};

const IndexPage = () => {

  const [modalShow, setModalShow] = useState(false);
  const [prestaToReserve, setPrestaToReserve] = useState({});
  const [allNextDepartures, setAllNextDepartures] = useState([]);
  const [partialNextDepartures, setPartialNextDepartures] = useState([]);

  const onReserveClick = (modalShow = false, prestaToReserve) => {
    setPrestaToReserve(prestaToReserve)
    setModalShow(modalShow)
  }

  useEffect(() => {

    const nextDepartures = require("../../data/next-departures.json")
    const allNextDepartures = getAllNextDepartures(nextDepartures)
    const partialNextDepartures = getPartialNextDepartures(allNextDepartures)
    setAllNextDepartures(allNextDepartures)
    setPartialNextDepartures(partialNextDepartures)

  }, [])


  return (
    <Container>
      <div class="d-flex justify-content-md-center align-items-center vh-100">
        <div>
          <img src={goat} />
        </div>
        <div>
          <p>Site web en construction...</p>
          <br />
          <p>Pour des randonnées dans les Pyrénées contactez-moi sur <Button href="mailto:lecabriquiflane@gmail.com">lecabriquiflane@gmail.com</Button></p>
          <br />
          <p>Le Cabri!</p>
        </div>
      </div>
    </Container>
  )


  return (

    <Layout>

      <SEO title="Accueil" />
      <Container>
        <Row className="mt-4"><CardsTypeRando /></Row>

        <h1 className="mt-4">Prochains départs</h1>
        <div className="mt-4">
          <Carousel swipeable keyBoardControl autoPlaySpeed={4000} partialVisible={true} infinite responsive={responsive}>
            {partialNextDepartures.map((el, i) => <div key={i} style={{ padding: "0.3rem" }}>
              <CardProchainsDeparts key={i} prestaInstance={el} onReserveClick={(a, b) => onReserveClick(a, b)} />
            </div>)}
          </Carousel>
        </div>

        <h1 className="mt-4">Calendrier des prestations</h1>
        Vous trouverez ci-dessous l’ensemble des prestations prévues au calendrier, classées par ordre chronologique.<br /><br />
  Le départ est assuré dès que le nombre minimum de participants est atteint. <br /><br />
  Le nombre maximal de participants est indicatif. Je me réserve le droit de le modifier en fonction des conditions météo, de l’état du terrain ou de toute autre critère qu’il me paraîtra opportun de prendre en compte. <br /><br />
  Contactez-moi si vous voulez vous positionner sur un départ complet : les  départs complets n’acceptent plus de participants mais je tiens une liste d’attente en cas de désistement.<br /><br />
  Vous ne trouvez pas votre bonheur ? <a className="color-text" style={{ textDecoration: "underline" }} href="/contact">Contactez-moi</a> pour ouvrir un nouveau départ !

        <Row className="mt-4">
          <TablePrestations allNextDeparturesSorted={allNextDepartures} onReserveClick={(a, b) => onReserveClick(a, b)} />
        </Row>

        <h1 className="mt-4">A propos de la montagne...</h1>
        <Row className="p-4 mt-2 justify-content-center">
          <Col className="col-6 col-sm-6 col-md-4 col-lg-4 p-4 my-auto">
            <Image src={guide} className="rounded-circle w-100" />
          </Col>
          <Col className="col-12 col-sm-12 col-md-12 col-lg-8 p-4">
            <i>"L’homme, on a dit qu’il était fait de cellules et de sang. Mais en réalité, il est comme un feuillage. Il faut que le vent passe pour que ça chante."</i> Giono<br /><br /><br /><br />
            La montagne reste aujourd'hui un des rares espaces de liberté où la pression de l'homme reste raisonnée. Raisonnée, avant tout par les contraintes de ce milieu, qui ont forgés les traditions, les croyances et les légendes, parfois encore bien vivantes dans nos vallées, où se mêlent un amour profond et un respect teinté de crainte face à quelque chose qui demeure plus fort que nous, rebelle et indomptable.<br /><br />
            Les pratiques y évoluent plus lentement qu'ailleurs, sans doute parce que la montagne nous impose cette simplicité efficace qui implique de faire le tri dans nos besoins, de nous recentrer sur notre condition d'êtres humains et de retrouver un coeur d'enfant, de jouer, de vivre, de s'émerveiller, de se sentir vivant !<br /><br />
            La montagne nous parle d'humilité, de courage et d'amour. Sur cet océan figé, nous ne sommes que fourmis insignifiantes, mais nous avançons à chaque pas et la persévérance nous permet de réaliser des exploits, dont nous avions oublié que nous sommes capables. Là-haut, l'effort est couronné par le privilège de défier un peu le temps et l'espace et de se rapprocher pas à pas des étoiles et des dieux.<br /><br />
            C'est cet environnement merveilleux, foisonnant de vie et d'histoires, que je me propose de vous faire découvrir au cours de nos balades, à un rythme adapté et toujours en veillant à votre sécurité.
          </Col>
        </Row>
      </Container>

      <ModalReservation
        show={modalShow}
        centered
        backdrop="static"
        keyboard={false}
        prestatoreserve={prestaToReserve}
        onHide={() => setModalShow(false)}
      />


    </Layout >
  )
}
export default IndexPage
