import React from "react"

const BadgeLevel = ({ level, isGeneratedPage }) => {

    let label = ""
    switch (level) {
        case 3:
            label = "sportif"
            break
        case 2:
            label = "intermédiaire"
            break
        case 1:
            label = "facile"
            break
        default:
            label = ""
            break
    }

    const modifyRelatvePath = isGeneratedPage ? "." : ""

    return (
        <div className="m-0">
            <small><span className="p-0 m-0 align-bottom">Difficulté: </span></small>
            {Array(level).fill().map((el, i) => <img key={i} style={{ marginBottom: "0.4rem", height: "1.5rem", marginLeft: "0.1rem" }} src={"." + modifyRelatvePath + "/images/hiking_boot.svg"} alt="" />)}
            <small className="ml-2 align-bottom">{"(" + label + ")"}</small>
        </div >
    )

}

export default BadgeLevel