import React from "react"
import { Card, Button } from "react-bootstrap"
import moment from "moment"
import BadgeEtat from "./badge-etat"
import BadgeLevel from "./badge-level"
import DetailsRando from './icons-caracteristique-rando'
import 'moment/locale/fr'
moment.locale("fr")
const prestations = require('../../data/prestations.json')


const RandoCard = ({ prestaInstance, onReserveClick }) => {

    let date = moment(prestaInstance.date, "DD/MM/YYYY").format("dddd DD MMMM YYYY")
    date = date.split(" ").map(el => el[0].toUpperCase() + el.slice(1)).join(" ")
    date = prestaInstance.time ? date + " à " + prestaInstance.time : date;

    const daysDecimal = prestaInstance.diff / (1000 * 60 * 60 * 24)
    const days = Math.floor(daysDecimal)
    const hours = Math.floor((daysDecimal - days) * 24)
    const diffString = "dans " + days + " jour(s) et " + hours + " heure(s)"

    const presta = prestations.find(el => el.titre === prestaInstance.titre)
    presta.nextDepartures = [prestaInstance]
    const firstImgPath = presta.imgs[0]

    const toPrestaPage = () => {
        window.location.href = "/prestations/" + prestaInstance.titre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(" ", "-")
    }

    return (
        <Card className="clickable-card" >
            <div onClick={toPrestaPage}>
                <Card.Header>
                    <div>{date}</div>
                    <div><small>{diffString}</small></div>
                    <BadgeEtat etat={prestaInstance.etat} />
                </Card.Header>
                <Card.Img style={{ height: "15rem", objectFit: "cover" }} src={'./images/' + firstImgPath} ></Card.Img>
                <Card.Body className="pt-1">
                    <div className="pt-2">{prestaInstance.titre} - <small>{presta.location}</small></div>
                    <div className="pt-0 pb-2"><BadgeLevel level={presta.level} /></div>
                    <div><small><DetailsRando presta={presta} imgWidth={"1.5rem"} spaceImgTxt={"0.5rem"} /></small></div>
                </Card.Body>
            </div>
            <Button disabled={prestaInstance.etat === "complet"} className="reserver" onClick={() => onReserveClick(true, presta)} >Réserver</Button>
        </Card >
    )
}

export default RandoCard