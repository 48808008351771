
import React from "react"
import { Table, Button } from "react-bootstrap"
import BadgeEtat from "./badge-etat"
const prestations = require("../../data/prestations.json")

const objPrestations = prestations.reduce((prev, curr) => {
    return Object.assign(prev, { [curr.titre]: curr })
}, {})


const TablePrestations = ({ onReserveClick, allNextDeparturesSorted }) => {

    return (
        <Table className="color-text" bordered responsive>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Intitulé séjour</th>
                    <th><span className="vertical-text">Groupe min.</span></th>
                    <th><span className="vertical-text">Nb. de pers. inscrites</span></th>
                    <th><span className="vertical-text">Groupe max.</span></th>
                    <th><span className="vertical-text">Distance (km)</span></th>
                    <th><span className="vertical-text">Temps</span></th>
                    <th><span className="vertical-text">Dénivelé(+/- en m)</span></th>
                    <th><span className="vertical-text">Niveau</span></th>
                    <th><span className="vertical-text">Public</span></th>
                    <th><span className="vertical-text">Tarif/pers.</span></th>
                    <th>Etat</th>
                    <th>Réserver</th>
                </tr>
            </thead>
            <tbody>
                {allNextDeparturesSorted.map((el, i) => {
                    const presta = objPrestations[el.titre]
                    return (<tr key={i}>
                        <td>{el.date}</td>
                        <td><a className="color-text" href={"/prestations/" + el.titre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(" ", "-")}>{el.titre}</a></td>
                        <td>{el.groupe.min}</td>
                        <td>{el.groupe.inscrits}</td>
                        <td>{el.groupe.max}</td>
                        <td>{presta.distance}</td>
                        <td>{presta.temps}</td>
                        <td>{presta["d+"]}/{presta["d-"]}</td>
                        <td>{presta.level}</td>
                        <td></td>
                        <td></td>
                        <td className="text-center"><BadgeEtat etat={el.etat} /></td>
                        <td><Button disabled={el.etat === "complet"} className="reserver" onClick={() => onReserveClick(true, { ...presta, nextDepartures: [el] })} >Réserver</Button></td>
                    </tr>)
                })}

            </tbody>
        </Table >
    )
}

export default TablePrestations